<template lang="pug">
  .page.entity-create

    actions-line(:loading="loading" @create="submitForm()")

    el-form.page__container.options-page__container(ref="postForm" :model="postForm" :rules="rules")

      .page__body(v-show="dataLoading")
        .page__loading Загрузка...
      .page__body(v-show="!dataLoading")
        tab-fields(:fields="fields" v-model="postForm" v-if="!fieldsHidden")

    created-dialog(ref="createdDialog" :created-id="lastCreatedId" @reset="reset()")

    back-to-top
</template>

<script>

  import TabFields from '@/components/fields/TabFields'

  import { notifyRequest, notifyErrorRequest } from '@/utils/api'
  import CreatedDialog from '@/components/PagePartials/EntityCreate/CreatedDialog'
  import ActionsLine from '@/components/PagePartials/EntityCreate/ActionsLine'
  import SaveRoutes from '@/views/Templates/SaveRoutes'
  import BackToTop from '@/components/BackToTop'

  export default {
    name: 'EntityCreate',
    extends: [SaveRoutes],
    components: {
      CreatedDialog,
      TabFields,
      ActionsLine,
      BackToTop
    },
    data() {
      const validateRequire = (rule, value, callback) => {
        if (value === '') {
          this.$message({
            message: rule.field + ' is required',
            type: 'error',
          })
          callback(new Error(rule.field + ' is required'))
        } else {
          callback()
        }
      }
      return {
        postForm: {},
        request: this.$route.meta.request,
        loading: true,
        dataLoading: true,
        fields: [],
        rules: {},
        fieldsHidden: false,
        lastCreatedId: 0
      }
    },
    computed: {
      listUrl() {
        return this.$route.fullPath.replace('/create', '/list')
      },
      lastCreatedLink() {
        return this.$route.fullPath.replace('/create', `/edit/${this.lastCreatedId}`)
      }
    },
    async created() {
      const fetchedData = await this.fetchData()
      this.postForm = {}
      this.fields = fetchedData.fields

      this.frontUrlPrefix = fetchedData.front_url_prefix
      this.addFrontPrefixToSlugs()
      this.loading = false
      this.dataLoading = false
    },
    methods: {
      addFrontPrefixToSlugs() {
        this.fields.forEach(el => {
          if (el.type === 'slug') {
            el.frontUrlPrefix = (this.frontUrlPrefix || '').replace(/\/$/, '') + '/'
          }
        })
      },
      async reset() {
        this.fieldsHidden = true
        await this.$nextTick()
        this.postForm = {}
        this.fieldsHidden = false
      },
      async fetchData() {
        this.dataLoading = true
        const responseData = await notifyErrorRequest({
          url: `${this.request}/create`,
          method: 'get'
        })
        this.dataLoading = false
        return responseData
      },
      async storeData(data) {
        this.loading = true
        const responseData = await notifyRequest({
          url: this.request,
          method: 'post',
          data: { data: data },
        }, 'Объект успешно создан')
        if (!responseData.success) {
          return
        }
        this.loading = false
        this.lastCreatedId = responseData.data.id
        this.$refs.createdDialog.show()
        return responseData
      },
      async submitForm() {
        this.$refs.postForm.validate(valid => {
          if (valid) {
            this.loading = true
            this.storeData(this.postForm)
            this.loading = false
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>
<style lang="sass">
  @import "@/assets/sass/mixin.scss"
</style>
